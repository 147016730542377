import React, {useState} from "../_snowpack/pkg/react.js";
import {getRandomName, wait} from "./lib/index.js";
export default function App() {
  const [name, setName] = useState(getRandomName());
  const [isCopying, setIsCopying] = useState();
  const generate = () => {
    setName(getRandomName());
  };
  const copy = async () => {
    window.navigator.clipboard.writeText(name);
    setIsCopying(true);
    await wait(600);
    setIsCopying(false);
  };
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: "name"
  }, name), /* @__PURE__ */ React.createElement("div", {
    className: "actions"
  }, /* @__PURE__ */ React.createElement("button", {
    onClick: generate
  }, "🔀 Something Else"), /* @__PURE__ */ React.createElement("button", {
    onClick: copy
  }, "📋 ", isCopying ? "Copied" : "Copy")));
}
